import { createGlobalStyle } from "styled-components"

const Global = createGlobalStyle`
    .content {
        h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
            &:not(:first-child) {
                padding-top: 1.4rem;
            }
        }

        li {
            &:last-child {
                padding-bottom: 0;
            }
        }

        h1, h2, h3, h4, h5, h6 {
            line-height: 1.6;
        }

        h1 {
            font-size: 1.8rem;

            &:after {
                content: 'This is not a good idea to use h1 tag here. You are breaking the website SEO.';
            }
        }

        h2 {
            font-size: 1.6rem;
        }

        h3 {
            font-size: 1.4rem;
        }

        h4 {
            font-size: 1.25rem;
        }

        h5 {
            font-size: 1.15rem;
        }

        h6 {
            font-size: 1.05rem;
        }

        strong,
        b {
            font-weight: 600;
        }

        em {
            font-style: italic;
        }

        a {
            display: inline-flex;
            position: relative;

            color: var(--main-green);

            transition: opacity 200ms ease;
            pointer-events: var(--pointer-events, auto);

            &:hover {
                opacity: 0.7;
            }

            &::after {
                position: absolute;
                content: "";

                width: 100%;
                height: 2px;

                bottom: 0;
                left: 0;

                background-color: var(--main-green);
                transition: background-color 200ms ease;
            }
        }

        blockquote {
            border-left: 5px solid var(--main-green);

            &:not(:first-child) {
                margin: 2.5rem 0 1rem 2rem;

                padding: 0 0 0 2rem;
            }
        }
    }

    .cursorPointer {
        &:hover {
            cursor: pointer;
        }
    }

    .full {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .absolute {
        position: absolute;
    }
`

export default Global
