import { useEffect, useCallback, useRef, useState } from "react"

const useStickyElement = () => {
  const [isSticky, setStickiness] = useState(false)
  const elementToApplyStickiness = useRef(null)

  const positionOnWhichStickinessIsApplied = elementToApplyStickiness.current
    ? elementToApplyStickiness.current.getBoundingClientRect().bottom
    : 1

  const handleScroll = useCallback(() => {
    window.scrollY > positionOnWhichStickinessIsApplied
      ? setStickiness(true)
      : setStickiness(false)
  }, [positionOnWhichStickinessIsApplied])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    handleScroll()

    return () => window.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  return { isSticky, elementToApplyStickiness }
}

export default useStickyElement
