import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

const ButtonGroup = styled.footer`
  display: flex;
  margin-top: 1.8rem;

  @media ${maxD.mobileM} {
    flex-direction: column;
    align-items: flex-start;
  }

  & > a,
  & > button {
    &:first-of-type {
      @media ${minD.mobileL} {
        margin-right: 1.8rem;
      }

      @media ${minD.mobileM} {
        margin-right: 1.4rem;
      }

      @media ${maxD.mobileM} {
        margin-bottom: 1rem;
      }
    }
  }
`

export default ButtonGroup
