import styled, { css } from "styled-components"

export const unstyledList = css`
  --dots-appearance: none;

  --left-padding: 0;
  --bottom-padding: 0;
`

export const UnstyledList = styled.ul`
  ${unstyledList}
`
