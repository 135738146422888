import { Field } from "formik"
import styled from "styled-components"

import * as helpers from "../../../assets/styles/helpers/common"

const Error = styled.span`
  font-size: 0.75rem;
  line-height: 1.2;

  color: var(--color, var(--red));
`

const FancyError = styled(Error)`
  display: flex;
  align-items: var(--align, center);
  position: absolute;

  width: 200px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;

  padding: var(--padding, 0 20px);
  border-radius: 0 var(--field-radius) var(--field-radius) 0;
  background-color: var(--red);

  ${({ theme }) =>
    theme === "big" && "--padding: 20px 25px; --align: flex-start;"}
`

const DefaultField = styled(Field)`
  position: relative;
  border: 1px solid var(--input-theme, var(--main-green));

  transition: box-shadow 300ms, border-color 300ms;

  &:focus {
    box-shadow: -0.2px 10px 8.8px 0.2px
      var(--input-transparent-theme, var(--main-green-transparent-min));
  }

  ${Error} + & {
    --input-theme: var(--red);
    --input-transparent-theme: var(--red-transparent-min);
  }

  &[disabled] {
    opacity: 0.5;
  }
`

const HiddenLabel = styled.label`
  ${helpers.hideElement}
`

const FormLine = styled.p`
  display: flex;
  position: relative;
  margin-bottom: 20px;

  --field-radius: 26px;
`

const TextField = styled(DefaultField)`
  width: 100%;
  height: 54px;

  padding: 25px 30px;
  border-radius: var(--field-radius);
`

const TextArea = styled(TextField)`
  height: 240px;
`

const CheckboxContainer = styled.label`
  display: flex;
  align-items: flex-start;

  font-size: 0.85rem;
  font-weight: 300;

  padding: 0.5rem 0 2rem;
`

const Checkbox = styled(DefaultField)`
  --checkbox-size: 1.8rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: var(--checkbox-size);
  height: var(--checkbox-size);

  border-radius: 50%;
  margin: 0.2rem 1.1rem 0 0;
  border: 1px solid var(--input-theme, var(--main-green));

  background-color: transparent;

  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  &::after {
    content: "";
    display: block;

    width: calc(var(--checkbox-size) * 0.25);
    height: calc(var(--checkbox-size) * 0.4);

    position: absolute;
    left: calc(var(--checkbox-size) * 0.36);
    top: calc(var(--checkbox-size) * 0.2);

    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;

    opacity: var(--after-opacity, 0);
    transform: rotate(var(--rotate-deg, 20deg));

    transition: transform var(--checking-transform-animation-time, 300ms)
        var(--checking-cubic-bezier, ease),
      opacity var(--checking-opacity-animation-duration, 200ms);
  }

  &:checked {
    --checking-opacity-animation-duration: 300ms;
    --checking-transform-animation-time: 600ms;
    --checking-cubic-bezier: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    --rotate-deg: 43deg;

    --after-opacity: 1;

    background-color: var(--input-theme, var(--main-green));
  }
`

const SuccessAlert = styled.h3`
  padding-top: 30px;
`

export {
  Error,
  FancyError,
  HiddenLabel,
  FormLine,
  TextField,
  TextArea,
  CheckboxContainer,
  Checkbox,
  SuccessAlert,
}
