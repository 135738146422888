import { useCookies } from "react-cookie";

const SiteLanguage = () => {
    const [cookies] = useCookies(["language"]);
    
    const SiteLanguage = cookies.language;

    return SiteLanguage;
};

export default SiteLanguage;

