import React from "react"
import styled from "styled-components"
import { Link as LinkTo } from "gatsby"

const NavItem = styled.li`
  ${props => props.alignment === "horizontal" && "padding: 0 0.8rem;"}
  ${props => props.alignment === "vertical" && "padding: 0 0 1.2rem;"}
`

const NavLink = styled(LinkTo)`
  color: var(--footer-text);

  transition: opacity 200ms ease;

  &:hover {
    color: var(--main-green);
  }
`

export const NavElements = ({ elements, alignment }) => {
  if (!elements) return null

  return elements.map(({ path, label, id }) => (
    <NavItem key={id} {...{ alignment }}>
      <NavLink to={path}>{label}</NavLink>
    </NavItem>
  ))
}
