import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { NavList } from "./NavList"

const ACFQuery = graphql`
  query {
    wpMenu(slug: { eq: "top-nav-pl" }) {
      menuItems {
        nodes {
          path
          label
          id
          parentId
          childItems {
            nodes {
              path
              label
              id
            }
          }
        }
      }
    }
  }
`

const PolishNavList = ({ isActive }) => {
  const items = useStaticQuery(ACFQuery).wpMenu.menuItems.nodes

  return <NavList {...{ isActive, items }} />
}

export default PolishNavList
