import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

export const TitleH1 = styled.h1`
  --theme-color: var(--main-green);

  span {
    color: var(--theme-color);
  }

  @media ${maxD.tablet} {
    br {
      display: none;
    }
  }
`

export const Title = styled.h2`
  --theme-color: var(--main-green);

  span {
    color: var(--theme-color);
  }

  @media ${maxD.tablet} {
    br {
      display: none;
    }
  }
`

export const MainTitle = styled(Title)`
  font-size: 2.8rem;
  padding-bottom: var(--padding-bottom, 0.7rem);

  @media ${maxD.desktop} {
    font-size: 2.5rem;
  }

  @media ${maxD.laptop} {
    font-size: 2.2rem;
  }

  @media ${maxD.mobileL} {
    font-size: 2rem;
  }
`

export const MainTitleH1 = styled(TitleH1)`
  font-size: 2.8rem;
  padding-bottom: var(--padding-bottom, 0.7rem);

  @media ${maxD.desktop} {
    font-size: 2.5rem;
  }

  @media ${maxD.laptop} {
    font-size: 2.2rem;
  }

  @media ${maxD.mobileL} {
    font-size: 2rem;
  }
`

export const CenterTitle = styled(MainTitle)`
  --padding-top: 4rem;
  --padding-bottom: 4rem;

  max-width: 850px;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  margin: 0 auto;

  text-align: center;

  @media ${maxD.desktop} {
    padding-bottom: calc(var(--padding-bottom) * 0.85);
  }

  @media ${maxD.laptop} {
    padding-top: calc(var(--padding-top) * 0.85);
  }

  @media ${maxD.tabletL} {
    padding-top: calc(var(--padding-top) * 0.7);
    padding-bottom: calc(var(--padding-bottom) * 0.8);
  }
`

export const CenterTitleWide = styled(CenterTitle)`
  max-width: 100%;
  padding-bottom: 20px;
`

export const SecondaryTitle = styled(Title)`
  padding-bottom: var(--spacing-bottom, 20px);

  font-size: 1.6rem;
  line-height: 1.5;

  @media ${maxD.tablet} {
    padding-bottom: calc(var(--spacing-bottom, 20px) * 0.7);
  }

  @media ${maxD.mobileL} {
    font-size: 1.4rem;
  }
`

export const IndentedTitle = styled(MainTitle)`
  max-width: 590px;

  padding-bottom: var(--space, 150px);

  @media ${minD.laptop} {
    padding-left: var(--indent, var(--section-spacing));
  }

  @media ${maxD.laptop} {
    padding-bottom: calc(var(--space, 150px) * 0.75);
  }

  @media ${maxD.tablet} {
    padding-bottom: 45px;
  }
`

export const WideIndentedTitle = styled(IndentedTitle)`
  --indent: calc(var(--section-spacing) * 2);
  max-width: 100%;
`

export const BiggerDescription = styled.p`
  font-size: 1.15rem;
`

export const BiggerDescriptionDiv = styled.div`
  font-size: 1.15rem;
`

export const BigDescription = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
`

export const BigDescriptionDiv = styled.div`
  font-size: 1.125rem;
  font-weight: 400;
`

export const MiddleSpacedBigDescription = styled(BigDescription)`
  padding: 1.6rem 0 2rem;
`

export const MiddleSpacedBigDescriptionDiv = styled(BigDescriptionDiv)`
  padding: 1.6rem 0 2rem;
`

export const GreenText = styled.p`
  color: var(--main-green);
`

export const CenterText = styled.p`
  text-align: center;
`
