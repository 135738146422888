import { css } from "styled-components"

export const hideElement = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`

export const pointer = css`
  &:hover {
    cursor: pointer;
  }
`

export const ornament = css`
  display: block;
  position: absolute;
  content: "";
`

export const noAppearance = css`
  -webkit-appearance: none;
  -moz-appearance: none;
`

export const notClickableButVisibleContent = css`
  z-index: var(--max-z-index);
  pointer-events: none;
`

export const isLinkAnAnchor = link => (link.includes("#") ? 1 : 0)
