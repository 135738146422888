import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { minD, maxD } from "../../../assets/styles/helpers/devices"
import { dotsPseudoelement, FadedGreenBigBox, line } from "./ornaments"

const align = alignment => {
  switch (alignment) {
    case "fe":
      return "flex-end"
    case "fs":
      return "flex-start"
    case "c":
      return "center"
    default:
      return null
  }
}

export const GrayContainer = styled.div`
  background-color: ${({ darker }) =>
    darker ? "var(--less-light-gray)" : "var(--light-gray)"};
`

export const BannerContainer = styled(GrayContainer).attrs({
  as: "header",
})`
  padding: var(--default-padding, 180px 0 0);

  position: relative;
  overflow: hidden;

  @media ${maxD.laptop} {
    padding: var(--laptop-padding, 160px 0 0);
  }

  @media ${maxD.tablet} {
    padding: var(--tablet-padding, 160px 0 0);
  }

  @media ${maxD.mobileM} {
    padding: var(--mobile-padding, 150px 0 0);
  }

  &::after {
    ${dotsPseudoelement}

    width: 700px;
    height: 450px;

    top: 200px;
    right: min(300px, 30vw);

    --dots-z-index: 0;
  }
`

export const ShadowBoxContainer = styled(FadedGreenBigBox).attrs({
  as: "div",
})`
  --box-width: 520px;
  --box-height: 700px;

  &::before {
    left: -100px;
    top: 0;
    background-color: var(--faded-green);

    z-index: -1;

    @media ${maxD.laptopL} {
      content: none;
    }
  }
`

export const Wrapper = styled.section`
  --wrapper-width: 1460px;
  --wrapper-right-padding: 50px;

  @media ${maxD.desktop} {
    --wrapper-width: 1356px;
  }

  @media ${maxD.laptopL} {
    --wrapper-width: 1170px;
    --wrapper-right-padding: 30px;
  }

  @media ${maxD.laptop} {
    --wrapper-width: 95%;
  }

  @media ${maxD.tabletL} {
    --wrapper-width: 690px;
  }

  @media ${maxD.tablet} {
    --wrapper-width: 500px;
  }

  @media ${maxD.mobileL} {
    --wrapper-width: calc(100vw - 50px);
    --wrapper-right-padding: 0;
  }

  display: ${({ flex }) => flex && "flex"};
  justify-content: ${({ spaceBetween }) => spaceBetween && "space-between"};
  align-items: ${({ alignItems }) => align(alignItems)};
  flex-wrap: ${({ wrapped }) => wrapped && "wrap"};
  position: ${({ relative }) => relative && "relative"};

  width: var(--wrapper-width);
  max-width: 100%;
  padding-right: var(--wrapper-right-padding);

  margin-left: auto;
  margin-right: auto;
`

export const LineWrapper = styled(Wrapper)`
  ${line}
`

export const LineWrapperWithBottomSpace = styled(LineWrapper)`
  padding-bottom: var(--section-spacing);
`

export const LineWrapperWithTopSpace = styled(LineWrapper)`
  padding-top: var(--section-spacing);
`

export const LineWrapperWithSpaces = styled(LineWrapperWithBottomSpace)`
  padding-top: var(--section-spacing);
`

export const LineWrapperWithReversedSpace = styled(LineWrapper)`
  margin-top: calc(var(--section-spacing) * -1);
`

export const WideWrapper = styled(Wrapper)`
  padding-right: 10px;
`

export const BannerWrapper = styled(LineWrapper)`
  &::before {
    @media ${minD.desktop} {
      height: calc(100% + 55px);
    }
  }

  &::after {
    top: -80px;

    @media ${maxD.laptop} {
      top: -30px;
    }

    @media ${maxD.tablet} {
      top: 0;
    }
  }

  @media ${maxD.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const FooterBackground = styled.footer`
  position: relative;
  color: var(--footer-text);
`

export const FooterContainer = ({ children }) => {
  const backgroundImageQuery = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "jpg/footer-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const backgroundImage =
    backgroundImageQuery?.file?.childImageSharp.gatsbyImageData

  return (
    <FooterBackground>
      <GatsbyImage image={backgroundImage} className="full" alt="bg" />
      {children}
    </FooterBackground>
  )
}
