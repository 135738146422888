import React from "react"
import styled from "styled-components"

import Fb from "./../../assets/images/svg/fb.svg"
import In from "./../../assets/images/svg/in.svg"
import Ig from "./../../assets/images/svg/ig.svg"
import Be from "./../../assets/images/svg/be.svg"
import Cl from "./../../assets/images/svg/clutch.svg"
import Tb from "./../../assets/images/svg/techbehemots.svg"

import { maxD } from "../../assets/styles/helpers/devices"
import { UnstyledList } from "./common/essentials"

const SocialMediaContainer = styled(UnstyledList)`
  display: flex;

  bottom: 70px;
  right: 90px;
  margin-left: -14px;

  @media ${maxD.desktop} {
    right: 30px;
  }

  @media ${maxD.laptopL} {
    right: 30px;
    bottom: 50px;
  }

  @media ${maxD.tablet} {
    bottom: 70px;
  }

  @media ${maxD.mobileS} {
    right: -12px;
  }
`

const SocialItem = styled.li`
  margin: 0 14px;

  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.7;
  }

  @media ${maxD.desktop} {
  }

  @media ${maxD.laptopL} {
    margin: 0 10px;
  }

  @media ${maxD.mobileL} {
    margin: 0 12px;
  }
`

const SocialLink = styled.a`
  svg {
    width: 16px;
    height: 16px;
  }

  svg,
  path {
    fill: ${props =>
      props.theme === "gray" ? "var(--footer-text);" : "var(--main-green);"};
  }
`

const SocialMedia = ({ theme, style, customList }) => (
  <SocialMediaContainer style={style}>
    {customList ? (
      <>
        {customList.map((item, i) => {
          const icon = item.icon
          const iconContent = icon === "custom" ? item.iconContent : null

          const link = item.link

          let IconComponent
          if (icon === "fb") {
            IconComponent = <Fb />
          } else if (icon === "in") {
            IconComponent = <In />
          } else if (icon === "ig") {
            IconComponent = <Ig />
          } else {
            IconComponent = null
          }

          if (IconComponent) {
            return (
              <SocialItem>
                <SocialLink href={link} target="_blank">
                  {IconComponent}
                </SocialLink>
              </SocialItem>
            )
          } else {
            return (
              <SocialItem>
                <SocialLink
                  href={link}
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: iconContent }}
                ></SocialLink>
              </SocialItem>
            )
          }
        })}
      </>
    ) : (
      <>
        <SocialItem>
          <SocialLink
            href="https://www.facebook.com/BraveNewAgency/"
            target="_blank"
            theme={theme}
          >
            <Fb />
          </SocialLink>
        </SocialItem>
        <SocialItem>
          <SocialLink
            href="https://www.linkedin.com/company/bravenewagency"
            target="_blank"
            theme={theme}
          >
            <In />
          </SocialLink>
        </SocialItem>
        <SocialItem>
          <SocialLink
            href="https://www.instagram.com/bravenew.agency/"
            target="_blank"
            theme={theme}
          >
            <Ig />
          </SocialLink>
        </SocialItem>
        <SocialItem>
          <SocialLink
            href="https://www.behance.net/bravenewagency"
            target="_blank"
            theme={theme}
          >
            <Be />
          </SocialLink>
        </SocialItem>
        <SocialItem>
          <SocialLink
            href="https://clutch.co/profile/brave-new/"
            target="_blank"
            theme={theme}
          >
            <Cl />
          </SocialLink>
        </SocialItem>
        <SocialItem>
          <SocialLink
            href="https://techbehemoths.com/company/brave-new/"
            target="_blank"
            theme={theme}
          >
            <Tb />
          </SocialLink>
        </SocialItem>
      </>
    )}
  </SocialMediaContainer>
)

export { SocialMedia }
