const minD = {
  mobileS: "(min-width: 321px)",
  mobileM: "(min-width: 415px)",
  mobileL: "(min-width: 568px)",
  tablet: "(min-width: 768px)",
  tabletL: "(min-width: 992px)",
  laptop: "(min-width: 1280px)",
  laptopL: "(min-width: 1440px)",
  desktop: "(min-width: 1740px)",
}

const betweenD = {
  mobileLTablet: "(min-width: 568px) and (max-width: 767px)",
  mobileLTabletL: "(min-width: 568px) and (max-width: 991px)",
  tabletTabletL: "(min-width: 768px) and (max-width: 991px)",
  tabletLaptop: "(min-width: 768px) and (max-width: 1279px)",
  tabletLLaptop: "(min-width: 992px) and (max-width: 1279px)",
  tabletLDesktop: "(min-width: 992px) and (max-width: 1739px)",
}

const maxD = {
  mobileS: "(max-width: 320px)",
  mobileM: "(max-width: 414px)",
  mobileL: "(max-width: 567px)",
  tablet: "(max-width: 767px)",
  tabletL: "(max-width: 991px)",
  laptop: "(max-width: 1279px)",
  laptopL: "(max-width: 1439px)",
  desktop: "(max-width: 1739px)",
}

export { minD, betweenD, maxD }
