import React from "react"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

const Hamburger = styled.button`
  display: none;
  width: 30px;
  height: 30px;
  margin-left: 30px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;

  @media ${maxD.tabletL} {
    display: block;
  }

  @media ${maxD.mobileL} {
    margin-left: 15px;
  }
`

const HamburgerInner = styled.span`
  position: relative;
  width: 30px;

  &,
  &::before,
  &::after {
    display: block;
    background-color: #000;
    height: 2px;
    transition: transform 200ms ease;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 15px;
  }

  &::before {
    top: -8px;
    left: 0;
  }

  &::after {
    top: 8px;
    right: 0;
  }

  &.active {
    transform: rotate(-45deg);

    &::before {
      transform: rotate(90deg) translateY(-8px);
    }

    &::after {
      transform: rotate(90deg) translateY(7px);
    }
  }
`

const HamburgerMenu = ({ handleHamburgerClick, isActive }) => {
  return (
    <Hamburger onClick={handleHamburgerClick}>
      <HamburgerInner className={isActive && "active"} />
    </Hamburger>
  )
}

export { HamburgerMenu }
