import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { NavElements } from "../NavElements"

const NavData = graphql`
  {
    wpMenu(slug: { eq: "footer-bottom-pl" }) {
      menuItems {
        nodes {
          path
          label
          id
        }
      }
    }
  }
`

const EnglishBottomNav = () => {
  const navData = useStaticQuery(NavData)
  const elements = navData.wpMenu.menuItems.nodes

  return <NavElements {...{ elements }} alignment="horizontal" />
}

export default EnglishBottomNav
