import styled from "styled-components"
import { Link as LinkTo } from "gatsby"

import { notClickableButVisibleContent } from "../../../assets/styles/helpers/common"
import { overlay } from "./ornaments"

export const ClassicLink = styled(LinkTo)`
  background: linear-gradient(
      transparent calc(100% - 2px),
      var(--underline-color, var(--dark-gray)) 10px
    )
    no-repeat;
  background-size: 0% 100%;

  transition: background-size 600ms, opacity 300ms;

  &:hover {
    background-size: 100% 100%;
  }
`

export const UnderlinedLink = styled(LinkTo)`
  --link-color: var(--main-green);

  display: inline-flex;
  align-items: center;
  flex-shrink: 0;

  position: relative;

  color: var(--link-color);
  font-size: 1rem;

  transition: opacity 200ms ease;
  pointer-events: var(--pointer-events, auto);

  &:hover {
    opacity: 0.7;
  }

  &::after {
    position: absolute;
    content: "";

    width: 100%;
    height: 2px;

    bottom: -2px;
    left: 0;

    background-color: var(--link-color);
    transition: background-color 200ms ease;
  }

  svg {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: 0.5rem;

    fill: var(--link-color);

    ${({ anchor }) => anchor && "transform: rotate(90deg);"}
  }
`

export const PseudoUnderlinedLink = styled(UnderlinedLink)`
  ${notClickableButVisibleContent}
`

export const ImageLink = styled(LinkTo)`
  position: var(--position, relative);

  display: flex;
  overflow: hidden;

  &::before {
    display: block;
    content: "";

    z-index: var(--overlay-z-index, 7);
    opacity: 0;
    transform: translateY(100%);

    ${overlay}
    background-color: var(--overlay-color, var(--main-green-transparent-max));

    transition: opacity 600ms ease, transform 1000ms var(--fancy-cubic-bezier);
  }

  &:hover {
    &::before {
      opacity: 1;
      transform: translateY(0);
    }
  }
`
