import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { minD, maxD } from "../../assets/styles/helpers/devices"
import { Wrapper } from "./common/containers"
import { SocialMedia } from "./SocialMedia"
import { UnstyledList } from "../../components/global/common/essentials"

import EnglishColumnsNav from "./footer/columns/EnglishColumnsNav"
import PolishColumnsNav from "./footer/columns/PolishColumnsNav"

import EnglishBottomNav from "./footer/bottom/EnglishBottomNav"
import PolishBottomNav from "./footer/bottom/PolishBottomNav"

import SiteLanguage from "../../helpers/siteLanguage"

const ACFQuery = graphql`
  {
    wp {
      footer {
        en {
          footerLogo {
            altText
            localFile {
              publicURL
            }
          }
          footerContactHours {
            bold
            normal
          }
          footerContactAddressAbroad {
            bold
            normal
          }
          footerContactAddress {
            bold
            normal
          }
          footerContactHeader
          footerRightHeader
        }
        pl {
          footerLogoPl {
            altText
            localFile {
              publicURL
            }
          }
          footerContactHoursPl {
            boldPl
            normalPl
          }
          footerContactAddressAbroadPl {
            boldPl
            normalPl
          }
          footerContactAddressPl {
            boldPl
            normalPl
          }
          footerContactHeaderPl
          footerRightHeaderPl
        }
      }
    }
  }
`

const FooterContainer = styled(UnstyledList)`
  border-top: 1px solid var(--line-gray);
`

const FooterTop = styled(Wrapper)`
  padding: 50px 0 45px;

  @media ${maxD.laptopL} {
    flex-direction: column;
  }

  @media ${maxD.mobileL} {
    flex-direction: column;
    padding: 50px 0 20px;
  }
`

const FooterColumn = styled.div`
  width: 100%;

  &:nth-child(1),
  &:nth-child(3) {
    @media ${minD.laptopL} {
      width: calc(50% - 200px);
    }
  }

  &:nth-child(2) {
    @media ${minD.mobileL} {
      display: flex;
      align-items: flex-end;
    }

    @media ${minD.laptopL} {
      flex-direction: column;
      align-items: center;

      width: 220px;
      margin-top: 70px;
    }

    @media ${maxD.laptopL} {
      order: 3;
    }
  }

  &:not(:nth-child(2)) {
    @media ${maxD.laptopL} {
      margin-bottom: 30px;
    }
  }
`

const FooterHeader = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 35px;

  color: #fff;
`

const Address = styled.address`
  display: flex;
  flex-wrap: wrap;
`

const AddressItem = styled.p`
  padding: 0 2rem 1.2rem 0;

  line-height: 1.75;

  @media ${minD.mobileL} {
    width: 50%;
  }

  span {
    font-weight: 600;
  }
`

const FooterLogo = styled.img`
  max-width: 150px;
  margin: 0 0 35px;

  @media ${maxD.laptopL} {
    margin: 0.8rem calc(50% - 160px) 0 0;
  }

  @media ${maxD.laptop} {
    margin: 0.8rem calc(50% - 155px) 0 0;
  }

  @media ${maxD.mobileL} {
    margin: 0.8rem 0 2.3rem 0;
  }
`

const FooterSocialMedia = styled.div`
  @media ${minD.mobileL} {
    margin-left: 14px;
  }
`

const ColumnsNav = styled.nav`
  @media ${minD.mobileL} {
    columns: 2;
  }
`

const FooterBottom = styled(Wrapper)`
  padding: 35px 0;
  border-top: 1px solid var(--line-gray);

  @media ${maxD.laptop} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${maxD.tablet} {
  }
`

const AdditionalNav = styled.nav`
  display: flex;

  @media ${maxD.laptop} {
    flex-wrap: wrap;
    margin: 0.8rem 0 0 -0.8rem;
  }
`

const Footer = () => {
  const acfData = useStaticQuery(ACFQuery)
  const enData = acfData.wp.footer.en
  const plData = acfData.wp.footer.pl

  let headers, address, logo

  if (SiteLanguage() === "en") {
    headers = {
      contact: enData.footerContactHeader,
      right: enData.footerRightHeader,
    }

    address = {
      domestic: {
        bold: enData.footerContactAddress?.bold,
        normal: enData.footerContactAddress?.normal,
      },
      abroad: {
        bold: enData.footerContactAddressAbroad?.bold,
        normal: enData.footerContactAddressAbroad?.normal,
      },
      hours: {
        bold: enData.footerContactHours?.bold,
        normal: enData.footerContactHours?.normal,
      },
    }

    logo = {
      url: enData.footerLogo?.localFile.publicURL,
      alt: enData.footerLogo?.altText,
    }
  } else {
    headers = {
      contact: plData.footerContactHeaderPl,
      right: plData.footerRightHeaderPl,
    }

    address = {
      domestic: {
        bold: plData.footerContactAddressPl?.boldPl,
        normal: plData.footerContactAddressPl?.normalPl,
      },
      abroad: {
        bold: plData.footerContactAddressAbroadPl?.boldPl,
        normal: plData.footerContactAddressAbroadPl?.normalPl,
      },
      hours: {
        bold: plData.footerContactHoursPl?.boldPl,
        normal: plData.footerContactHoursPl?.normalPl,
      },
    }

    logo = {
      url: plData.footerLogoPl?.localFile.publicURL,
      alt: plData.footerLogoPl?.altText,
    }
  }

  const currentYear = new Date().getFullYear()

  return (
    <FooterContainer as="article">
      <FooterTop flex alignItems="fs" spaceBetween>
        <FooterColumn>
          <FooterHeader>{headers.contact}</FooterHeader>
          <Address>
            <AddressItem>
              <span>{`${address.domestic.bold} `}</span>
              <br />
              {address.domestic.normal}
            </AddressItem>
            <AddressItem>
              <span>{`${address.abroad.bold} `}</span>
              <br />
              {address.abroad.normal}
            </AddressItem>
            <AddressItem>
              <span>{`${address.hours.bold} `}</span>
              <br />
              {address.hours.normal}
            </AddressItem>
          </Address>
        </FooterColumn>
        <FooterColumn>
          {logo && <FooterLogo src={logo.url} alt={logo.alt} />}
          <FooterSocialMedia>
            <SocialMedia theme="gray" />
          </FooterSocialMedia>
        </FooterColumn>
        <FooterColumn>
          <FooterHeader>{headers.right}</FooterHeader>
          <ColumnsNav>
            {SiteLanguage() === "en" ? (
              <EnglishColumnsNav />
            ) : (
              <PolishColumnsNav />
            )}
          </ColumnsNav>
        </FooterColumn>
      </FooterTop>
      <FooterBottom flex spaceBetween>
        <p>
          © Copyright {currentYear} - <span>Brave New</span>
        </p>
        <AdditionalNav>
          {SiteLanguage() === "en" ? <EnglishBottomNav /> : <PolishBottomNav />}
        </AdditionalNav>
      </FooterBottom>
    </FooterContainer>
  )
}

export default Footer
