import { Link as LinkTo } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

export const dotsPseudoelement = css`
  display: block;
  content: "";
  position: absolute;

  pointer-events: none;
  z-index: var(--dots-z-index, -1);

  background: var(--dots) no-repeat;
  background-size: contain;
`

export const box = css`
  &::after {
    display: block;
    content: "";
    position: absolute;
    z-index: 1;

    width: 65px;
    height: 65px;
  }
`

export const greenBox = css`
  ${box}

  &::after {
    background-color: var(--main-green);
  }
`

export const fadedGreenBox = css`
  ${box}

  &::after {
    background-color: var(--faded-green);
  }
`

export const fadedGreenBigBox = css`
  &::before {
    display: block;
    content: "";
    position: absolute;

    width: var(--box-width, 100%);
    height: var(--box-height, 100%);

    z-index: -1;

    background-color: var(--faded-green);
  }
`

export const line = css`
  position: relative;

  &::before,
  &::after {
    display: block;
    position: absolute;
    z-index: 9;

    background-color: var(--color, var(--faded-green));
  }

  &::before {
    @media ${minD.mobileL} {
      content: "";
    }

    width: 1px;
    height: 100%;

    right: 0;
    bottom: 0;
  }

  &::after {
    @media ${minD.mobileL} {
      content: var(--does-bold-line-exists, "");
    }

    width: 5px;
    height: 6.9rem;

    top: 9rem;
    right: -2px;
  }
`

export const quotePseudoelement = css`
  --quote-size: 85px;

  @media ${minD.laptop} {
    content: "";
    top: 32%;
  }

  @media ${minD.laptopL} {
    top: 37%;
  }

  display: block;
  position: absolute;

  width: var(--quote-size);
  height: var(--quote-size);

  top: 40%;
  z-index: 2;
  pointer-events: none;

  background: url('data:image/svg+xml, <svg fill="%233ab54a" height="512" viewBox="0 0 409.294 409.294" width="512" xmlns="http://www.w3.org/2000/svg"><path d="M233.882 29.235v175.412h116.941c0 64.48-52.461 116.941-116.941 116.941v58.471c96.728 0 175.412-78.684 175.412-175.412V29.235zM0 204.647h116.941c0 64.48-52.461 116.941-116.941 116.941v58.471c96.728 0 175.412-78.684 175.412-175.412V29.235H0z"/></svg>')
    no-repeat center;
  background-size: contain;
  transform: translateY(-50%);

  @media ${maxD.tabletL} {
    --quote-size: 60px;
  }
`

export const DotsContainer = styled.section`
  overflow: hidden;

  &::after {
    ${dotsPseudoelement}
  }
`

export const QuoteContainer = styled.figure`
  &::after {
    ${quotePseudoelement}
  }
`

export const GreenBoxContainer = styled.figure`
  ${greenBox}
`

export const FadedGreenBoxContainer = styled.figure`
  ${fadedGreenBox}
`

export const FadedGreenBigBox = styled.figure`
  ${fadedGreenBigBox}
`

export const overlay = css`
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
`

export const Overlay = styled.img`
  ${overlay}
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
`

export const Square = styled(LinkTo)`
  &::after {
    display: block;
    position: relative;
    content: "";

    padding-bottom: 100%;
    isolation: isolate;
    pointer-events: none;

    transition: background-color 200ms ease;
  }
`

export const DoubleSquare = styled(Square)`
  &::after {
    @media ${minD.tablet} {
      padding-bottom: 200%;
    }
  }
`

export const arrow = css`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: var(--arrow-box-size);
  height: var(--arrow-box-size);

  background-color: var(--main-green);
  transition: background-color 200ms ease;

  &::after {
    display: block;
    content: "";
    position: absolute;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: var(--icon-arrow-background);
    background-size: 20px;

    transition: transform 200ms ease;

    @media ${maxD.mobileL} {
      background-size: 16px;
    }
  }

  &:hover {
    cursor: pointer;

    &::after {
      transform: translateX(5px);
    }
  }
`
